












import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { BlogFilter } from 'client-website-ts-library/filters';
import { WebsiteLevel } from 'client-website-ts-library/types';

import Blogs from '../components/Blogs.vue';

@Component({
  components: {
    Blogs,
  },
})
export default class Blog extends Mixins(View) {
  private filter = new BlogFilter({
    SearchGuid: 'e80aeeb1-c8c3-4e32-947c-16b01aaedfda',
    SearchLevel: WebsiteLevel.Profile,
  });
}
